import React from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const { author } = useStaticQuery(query).site.siteMetadata
  return (
    <Container className="footer text-muted text-center fixed-bottom">
      <span className="m-auto">
      <b>{author}</b> &copy; {new Date().getFullYear()}. Made with <a href="https://www.gatsbyjs.org/">Gatsby</a>
      </span>
    </Container>
  )
}
const query = graphql`
  query Author {
    site {
      siteMetadata {
        author
      }
    }
  }
`