import React, { useContext } from "react"
import { Form, Nav, Navbar } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import ThemeContext from "../utils/theme"

export default () => {
    const { title } = useStaticQuery(query).site.siteMetadata
    const { dark, toggleDark, toString } = useContext(ThemeContext)
    return (
      <Navbar variant={toString()} sticky="top" collapseOnSelect expand="md">
        <Navbar.Brand as={Link} to="/">{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
     >
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/projects">Projects</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
        <Form className="ml-3 my-auto">
            <Form.Check
              type="switch"
              id="custom-switch"
              label=""
              title="Toggle Theme"
              checked={dark}
              onChange={toggleDark}
            />
          </Form>
          </Navbar.Collapse>
      </Navbar>
    )
  }
  
  const query = graphql`
    query Title {
      site {
        siteMetadata {
          title
        }
      }
    }
  `